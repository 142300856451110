<template>
  <div class="intract-smart-list">
    <v-row justify="center" dense :class="`${filterClasses}`">
      <v-col cols="12" md="12" v-if="!disableSearch">
        <v-text-field
          hide-details="auto"
          prepend-inner-icon="mdi-magnify"
          solo
          v-model="search"
          label="Search"
          clearable
        ></v-text-field>
      </v-col>
      <!-- <div v-if="filterData"> -->
      <v-col
        v-for="(field, index) in Object.keys(!hideFilters && filterFields || {})"
        :key="index"
        cols="12"
        :md="filterWidth"
      >
        <div>
          <!-- custom (slot) filter field -->
          <slot
            v-if="filterFields[field].custom"
            :name="'custom-filter--' + field"
          ></slot>
          <!-- eslint-disable vue/no-mutating-props -->

          <v-select
            v-else
            :ref="`select-filter--${field}`"
            v-model="filterFields[field].model"
            :items="filterFields[field].items || selectionItems[field]"
            small-chips
            deletable-chips
            single-line
            solo
            color="primary"
            :label="filterFields[field].label"
            :multiple="filterFields[field].multiple"
            :value="filterFields[field].value"
            :readonly="filterFields[field].readonly"
            hide-details
            menu-props="closeOnContentClick"
            background-color="transparent"
            :item-value="filterFields[field].itemValue"
            :item-text="filterFields[field].itemText"
            class="rounded-input"
            @change="
              getItems();
              removeFocus(field);
            "
          >
            <template v-slot:item="data">
              <!-- HTML that describe how select should render items when the select is open -->
              <v-list-item-content>
                <v-list-item-title>{{
                  data.item[filterFields[field].itemText]
                }}</v-list-item-title>
                <v-list-item-subtitle v-if="filterFields[field].itemSubtitle">{{
                  Helper.getProp(data.item, filterFields[field].itemSubtitle)
                }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="filterFields[field].getSubtitle">{{
                    filterFields[field].getSubtitle(data.item)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </div>
      </v-col>
      <!-- </div> -->
    </v-row>
    <slot name="before-list"></slot>
    <slot name="list" :itemsList="itemsList">
      <div v-if="isLoading && !firstLoadDone" class="mt-2">
        <slot name="loading-component">
          <v-skeleton-loader
            class="my-0"
            type="list-item-three-line"
            max-height="70px"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="my-0"
            type="list-item-three-line"
            max-height="70px"
          ></v-skeleton-loader>
        </slot>
      </div>
      <v-list
        :class="noSpacing ? 'ma-0 pa-0' : ''"
        v-else-if="itemsList && itemsList.length"
      >
        <template>
          <div v-for="(item, index) in itemsList" :key="index">
            <slot name="list-item" :item="item" :itemIndex="index">
              <v-list-item
                :key="index"
                class="px-0 px-4"
                @click="itemOptions.handleClick(item)"
              >
                <slot name="list-item-avatar" :item="item">
                  <v-list-item-avatar
                    class="align-self-center"
                    v-if="itemOptions.avatar"
                  >
                    <v-img
                      :src="Helper.getProp(item, itemOptions.avatar)"
                    ></v-img>
                  </v-list-item-avatar>
                </slot>

                <slot name="list-item-content" :item="item">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      Helper.getProp(item, itemOptions.title)
                    }}</v-list-item-title>
                    <v-list-item-subtitle v-if="itemOptions.subtitle">{{
                      Helper.getProp(item, itemOptions.subtitle)
                    }}</v-list-item-subtitle>
                    
                  </v-list-item-content>
                </slot>
                <slot name="list-item-right" :item="item">
                  <v-list-item-icon
                    class="align-self-center"
                    :item="item"
                    v-if="itemOptions.appendIcon"
                    ><v-icon>{{
                      itemOptions.appendIcon
                    }}</v-icon></v-list-item-icon
                  >
                </slot>
              </v-list-item>
            </slot>
          </div>
        </template>
      </v-list>
      <div class="text-center mt-4" v-else-if="!isLoading && !paginated">
        <slot name="no-items-found" :items-list="itemsList">
          No items found</slot
        >
      </div>
    </slot>

    <infinite-loading
      v-if="paginated"
      ref="intract-smart-list-infinite-loader"
      :identifier="infiniteIdentifier"
      @infinite="getPaginatedItems"
      :key="infiniteLoaderKey"
    >
      <span slot="spinner">
        <span></span>
      </span>
      <div slot="no-results" class="mt-6">
        <span v-if="itemsList.length > 10">{{ noMoreDataText }}</span
        ><span v-else-if="itemsList.length < 10 && itemsList.length != 0"></span
        ><span v-else-if="!itemsList.length">
          <slot name="paginated-no-items-found" :items-list="itemsList">
            No items found</slot
          ></span
        >
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import Mixins from "@utils/mixins";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "IntractSmartList",
  mixins: [Mixins.essentials],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    endpoint: {
      type: String,
      default: null,
    },
    searchParameter: {
      type: String,
      default: "search",
    },
    itemOptions: {
      type: Object,
      default: () => null,
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    filterFields: {
      type: Object,
      default: () => null,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    noMoreDataText: {
      type: String,
      default: "No more data!",
    },
    noSpacing: {
      type: Boolean,
      default: false,
    },
    filterClasses: {
      type: String,
      default: "mx-2",
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
  },
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      // search: "",
      // filterData: this.filterFields,
      selectionItems: {},
      allItems: this.items,
      pagination: {
        nextUrl: null,
        count: null,
      },
      firstLoad: true,
      firstLoadDone: false,
      infiniteIdentifier: new Date().toString(),
      infiniteLoaderKey: null,
    };
  },
  computed: {
    filterWidth() {
      // return (12 / Object.keys(this.filterFields).length) < 6 ? 6 : 12;
      return 12 / Object.keys(this.filterFields).length;
    },
    itemsList() {
      var finalList = this.allItems;
      if (this.search && !this.paginated) {
        //only use generic search for non-paginated lists
        var self = this;
        finalList = finalList.filter((item) => {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(self.search.toLowerCase());
        });
      }
      this.$emit("updateAllItemsList", finalList);
      return finalList;
    },
  },
  methods: {

    getFilteredUrl(url) {
       if (this.filterFields) {
        Object.keys(this.filterFields).map((field) => {
          var fieldData = this.filterFields[field];
          if (fieldData.model)
            url = this.Helper.addUrlParams(
              url,
              `${fieldData.param}=${
                fieldData.multiple
                  ? fieldData.model.map((a) => a)
                  : fieldData.model
              }`
            );
        });
      }
      return url;
    },

    async getAllItems() {
      var url = this.getFilteredUrl(this.endpoint)
      this.allItems = await this.api.call(this.essentials, url);
      
      this.$emit("updateTotalCount", this.allItems.length);
      this.$emit("itemsReceived", this.allItems);
    },

    async getPaginatedItems(state) {

      if (!this.endpoint) return;
      if (!state) {
        //refresh
        this.firstLoad = true;
        this.pagination.nextUrl = this.pagination.count = null;
        this.allItems = [];

        // this.infiniteIdentifier = new Date().toString();
        this.infiniteIdentifier = new Date().toString();
        return;
        // return;
      }
      // if (this.isMoreLoading) return;
      // if (!this.pagination.nextUrl) return;

      if(!this.firstLoad && !this.pagination.nextUrl) return;
      var url = this.firstLoad
        ? this.endpoint
        : this.pagination.nextUrl ?? this.endpoint;
      this.firstLoad = false;
      if (this.search)
        url = this.Helper.addUrlParams(url, [
          `${this.searchParameter}=${this.search}`,
        ]);
      // filtering
      url = this.getFilteredUrl(url);
     
      var response = await this.api.call(this.essentials, url);
      if (response) {
        this.firstLoadDone = true;
        this.allItems.push(...response.results);
        this.pagination.nextUrl = response.next;
        this.pagination.count = response.count;
        if (state) {
          if (response.next == null) await state.complete();
          else await state.loaded();
        }
      }
      this.$emit("updateTotalCount", this.pagination.count ?? 0);
      this.$emit("itemsReceived", this.allItems);
    },
    async getItems() {
      if (!this.endpoint) {
        this.allItems = this.items;
      } else {
        if (this.paginated) this.getPaginatedItems();
        else this.getAllItems();
      }
    },
    async getFilterData() {
      if (!this.filterFields) return;
      if (Object.keys(this.filterFields).length > 0) {
        Object.keys(this.filterFields).map(async (field) => {
          var fieldData = this.filterFields[field];
          if (fieldData.endpoint) {
            this.selectionItems[field] = await this.api.call(
              this.essentials,
              fieldData.endpoint
            );
          } else {
            this.selectionItems[field] = fieldData.items;
          }
        });
      }
    },

    async removeFocus(field) {
      this.$refs["select-filter--" + field][0].blur();
    },

    async getItem(itemId) {
      return this.allItems.find((i) => i.id == itemId);
    },

    async replaceItem(itemId, itemObj) {
      var toBeChanged = this.allItems.find((i) => i.id == itemId);
      if (!toBeChanged) return false;
      for (var k in itemObj) toBeChanged[k] = itemObj[k];
    },

    async addItem(itemObj, first = false) {
      if (first) this.allItems.unshift(itemObj);
      else this.allItems.push(itemObj);
      this.$emit("updateTotalCount", this.allItems.length);
    },

    async removeItem(itemId) {
      this.allItems = this.allItems.filter((i) => i.id != itemId);
      this.$emit("updateTotalCount", this.allItems.length);
    },
  },
  created() {
    this.infiniteLoaderKey = this.Helper.generateUID();
    // this.getItems();
    this.getFilterData();
    this.$emit("updateGetItemsFunction", this.getItems);
  },
  watch: {
    search: {
      async handler() {
        if (!this.paginated) return;
        var searched = this.search;
        var self = this;
        setTimeout(async function () {
          if (searched == self.search) {
            await self.getPaginatedItems();
          }
        }, 500);
      },
    },
    endpoint: {
      async handler() {
        if (this.endpoint) {
          await this.getItems();
        }
      },
      immediate: true,
    },
    items: {
      async handler() {
        this.getItems();
      },
    },
  },
};
</script>